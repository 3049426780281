
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ApiAuth } from "@/core/api";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "user-overview",
  components: {},
  setup() {
    const loading = ref<boolean>(true);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const formData = ref({
      status: 0,
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
    });

    const getRoute = computed(() => {
      const name = route.name as string;
      if (name.match("user-management-system-users")) {
        return "/user-management/system-users/";
      }
      return "/user-management/saas-users/";
    });

    const getUserData = () => {
      loading.value = true;
      ApiAuth.getUserInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getUserData();
    });

    return {
      t,
      loading,
      formData,
      getRoute,
    };
  },
});
